import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Button from 'components/button';
import { graphql } from 'gatsby';

const Index = ({ data }) => (
  <Layout>
    <Box>
      <div style={{ height: '13vh' }} />
      <Title
        as="h1"
        size="large"
        style={{ marginLeft: '15vw', marginRight: '2vw' }}
      >
        {data.homeJson.title}
      </Title>
      <div style={{ height: '3vh' }} />
      <h5 style={{ marginLeft: '15vw', marginRight: '15vw' }}>
        {data.homeJson.subtitle}
      </h5>
      <div style={{ height: '8vh' }} />
      <div style={{ marginLeft: '20vw', marginRight: '16vw' }}>
        <Link to="/contact">
          <Button title="Contact Us" />
        </Link>
      </div>
    </Box>
    <div style={{ height: '18vh' }} />
    <div
      style={{ marginLeft: '17vw', marginRight: '17vw' }}
      dangerouslySetInnerHTML={{
        __html: data.homeJson.content.childMarkdownRemark.html,
      }}
    ></div>
    <div style={{ height: '5vh' }} />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      subtitle
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

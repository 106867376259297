import styled from 'styled-components';

export const Click = styled.button`
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    padding: 20px 38px;
    top: 0;
    font-size: 22px;
    border-radius: 5px;
    background: ${props =>
      !props.disabled ? props.color || '#c1ecbe' : '#e0e0e0'}
    color: #fff;
    box-shadow: 0px 0px 0px ${props =>
      !props.disabled ? props.color || '#c1ecbe' : '#e0e0e0'};
    
    
    -webkit-transition: all 0.3s ease;
       -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
  
  :hover {
    top: -10px;
    box-shadow: 0px 1px 1px ${props =>
      !props.disabled ? props.color || '#c1ecbe' : '#e0e0e0'};
    
    -webkit-transform: rotateX(20deg);
       -moz-transform: rotateX(20deg);
        -ms-transform: rotateX(20deg);
            transform: rotateX(20deg);
  }
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { accent } from 'constants/theme';
import { Click } from './button.css';

const Button = ({ title, type, disabled, style }) => (
  <Click color={accent} type={type} disabled={disabled} style={style}>
    {title}
  </Click>
);

Button.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default Button;
